/* mixins */
/* Custom mixins */
/* inspired by bootstrap */
#block_top_menu {
  height: 97px; }
  #block_top_menu .sf-menu {
    padding: 0 42px 0 200px; }
    @media (min-width: 992px) {
      #block_top_menu .sf-menu:after {
        content: '';
        display: block;
        position: absolute;
        right: 31px;
        border-right: 1px solid #595959;
        height: 15px;
        top: 2px; } }
    @media (max-width: 767px) {
      #block_top_menu .sf-menu {
        text-align: left;
        padding: 15px;
        display: none;
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 250px;
        background: #fff;
        z-index: 13;
        overflow: auto; } }

/*** ESSENTIAL STYLES ***/
.sf-contener {
  clear: both;
  float: left;
  width: 100%;
  height: 97px; }

.sf-menu,
.sf-menu * {
  margin: 0;
  padding: 0;
  list-style: none; }

.sf-menu {
  position: relative;
  padding: 0;
  width: 100%;
  text-align: right; }

.sf-menu ul {
  position: absolute;
  top: -999em;
  background: #fff; }
  @media (max-width: 767px) {
    .sf-menu ul {
      position: relative; } }

.sf-menu ul li {
  width: 100%; }

.sf-menu li:hover {
  visibility: inherit;
  /* fixes IE7 'sticky bug' */ }

.sf-menu > li {
  display: inline-block; }
  @media (max-width: 767px) {
    .sf-menu > li {
      float: none;
      position: relative;
      border-right: none;
      display: block;
      padding: 6px 30px 6px 0;
      text-align: left; }
      .sf-menu > li span {
        position: absolute;
        right: 6px;
        top: 0;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        z-index: 2;
        cursor: pointer; }
        .sf-menu > li span:hover {
          color: #e86342; }
        .sf-menu > li span:after {
          font-family: "FontAwesome";
          content: "\f107";
          font-size: 26px; }
        .sf-menu > li span.active:after {
          content: "\f106"; } }
  .sf-menu > li > a {
    color: #333333;
    font-family: "Kaleko 105 Round W01 Book", Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 300;
    display: block;
    padding: 0 13px;
    letter-spacing: 0.5px; }
    @media (max-width: 767px) {
      .sf-menu > li > a {
        padding: 0;
        color: #000; } }
  .sf-menu > li.sfHover > a,
  .sf-menu > li > a:hover,
  .sf-menu > li.sfHoverForce > a {
    color: #e86342; }
  .sf-menu > li.sfHover > a.sf-with-ul,
  .sf-menu > li > a.sf-with-ul:hover {
    position: relative; }
    .sf-menu > li.sfHover > a.sf-with-ul:after,
    .sf-menu > li > a.sf-with-ul:hover:after {
      display: block;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 15.5px 22px 15.5px;
      border-color: transparent transparent #ffffff transparent;
      position: absolute;
      bottom: -40px;
      z-index: 100;
      left: 50%;
      margin-left: -15.5px; }
  @media (min-width: 992px) {
    .sf-menu > li:nth-of-type(1) > a, .sf-menu > li:nth-of-type(2) > a {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
      margin: 1.5px 0;
      line-height: 15px; }
    .sf-menu > li:nth-of-type(1) > a {
      border-left: 1px solid #595959; }
    .sf-menu > li:nth-of-type(2) > a {
      border-right: 1px solid #595959; } }

.sf-menu li li li a {
  display: inline-block;
  position: relative;
  color: #000;
  font-size: 12px;
  line-height: 18px;
  font-weight: 300;
  padding-bottom: 7px; }
  .sf-menu li li li a:hover {
    color: #333; }

.sf-menu li ul {
  text-align: left;
  display: none;
  left: 0;
  top: 57px;
  /* match top ul list item height */
  z-index: 99;
  padding: 32px 0 28px 0;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 13px;
  -moz-box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 13px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 13px; }
  @media (max-width: 767px) {
    .sf-menu li ul {
      top: 0;
      padding: 0 0 10px; } }

.submenu-container {
  min-height: 397px;
  border-top: 1px solid #efeff0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }
  .submenu-container .left-links {
    position: absolute;
    left: 0;
    top: 31px;
    bottom: 31px;
    padding: 0 5px 0 71px;
    border-right: 1px solid #e2e2e2;
    width: 18.8%; }
    .submenu-container .left-links ul {
      padding: 0; }
    .submenu-container .left-links li {
      padding: 4px 0 28px; }
      .submenu-container .left-links li a {
        text-transform: uppercase;
        color: #000;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        padding: 0; }
        .submenu-container .left-links li a:before {
          content: none;
          display: none; }
  .submenu-container > li.category-li {
    padding: 10px 0 0 60px; }
  .submenu-container.has-left-addition {
    padding-left: 20%; }
    .submenu-container.has-left-addition > li.category-li {
      width: 25%; }
  .submenu-container.has-right-addition {
    padding-right: 20%; }
    .submenu-container.has-right-addition > li.category-li {
      width: 25%; }
  .submenu-container.has-left-addition.has-right-addition > li.category-li {
    width: 33.33333%; }

.sf-menu li li ul {
  position: static;
  display: block !important;
  opacity: 1 !important;
  background: none;
  -webkit-box-shadow: transparent 0px 0px 0px;
  -moz-box-shadow: transparent 0px 0px 0px;
  box-shadow: transparent 0px 0px 0px; }

.sf-menu li li li ul {
  padding: 0 0 0 20px; }

.sf-menu li li li ul {
  width: 220px; }

ul.sf-menu li:hover li ul,
ul.sf-menu li.sfHover li ul {
  top: -999em; }

ul.sf-menu li li:hover ul,
ul.sf-menu li li.sfHover ul {
  left: 200px;
  /* match ul width */
  top: 0; }

ul.sf-menu li li:hover li ul,
ul.sf-menu li li.sfHover li ul {
  top: -999em; }

ul.sf-menu li li li:hover ul,
ul.sf-menu li li li.sfHover ul {
  left: 200px;
  /* match ul width */
  top: 0; }

.sf-menu > li > ul {
  padding: 26px 30px 31px;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
  width: 100%; }
  .sf-menu > li > ul > li > a {
    text-transform: uppercase;
    font-size: 14px;
    color: #000; }
    .sf-menu > li > ul > li > a:hover {
      color: #515151; }
  @media (max-width: 767px) {
    .sf-menu > li > ul > li {
      padding-top: 5px; }
      .sf-menu > li > ul > li ul {
        padding-top: 10px; }
        .sf-menu > li > ul > li ul li {
          padding: 5px 0; }
          .sf-menu > li > ul > li ul li a {
            padding-bottom: 0; } }
  @media (max-width: 767px) {
    .sf-menu > li > ul {
      padding: 10px 0 5px 5px;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none; } }

.sf-menu > li > ul > li {
  float: left;
  width: 20%;
  padding-right: 15px; }
  @media (max-width: 767px) {
    .sf-menu > li > ul > li {
      width: 50%; } }
  @media (max-width: 767px) {
    .sf-menu > li > ul > li {
      width: 100%;
      padding-bottom: 10px; } }
  @media (min-width: 992px) {
    .sf-menu > li > ul > li.first-in-line-lg {
      clear: left; } }
  @media (min-width: 768px) and (max-width: 767px) {
    .sf-menu > li > ul > li.first-in-line-xs {
      clear: left; } }

.sf-menu > li > ul > li.category-thumbnail {
  width: 100% !important;
  float: none;
  clear: both;
  overflow: hidden;
  padding-right: 0; }
  .sf-menu > li > ul > li.category-thumbnail > div {
    float: left;
    padding-left: 10px;
    width: 33.333%; }
    @media (max-width: 767px) {
      .sf-menu > li > ul > li.category-thumbnail > div {
        width: 100%;
        padding-left: 0;
        padding-top: 10px;
        text-align: center; } }
    .sf-menu > li > ul > li.category-thumbnail > div:first-child {
      padding-left: 0; }
    .sf-menu > li > ul > li.category-thumbnail > div img {
      max-width: 100%;
      display: block; }

.cat-title {
  display: none; }
  @media (max-width: 767px) {
    .cat-title {
      display: block;
      text-transform: uppercase;
      color: #333;
      position: absolute;
      top: -43px;
      left: -15px;
      width: 43px;
      text-align: center;
      z-index: 11;
      background-color: #fff;
      cursor: pointer;
      -webkit-transition: all 0.4s ease-in-out;
      -moz-transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out; }
      .cat-title:hover {
        color: #e86342; }
      .cat-title:after {
        display: block;
        font-family: "FontAwesome";
        content: "\f0c9";
        font-size: 26px;
        line-height: 43px; }
      .cat-title.active:after {
        content: "\f104"; }
      .is-menu-opened .cat-title {
        -webkit-transform: translateX(250px);
        -moz-transform: translateX(250px);
        -ms-transform: translateX(250px);
        -o-transform: translateX(250px);
        transform: translateX(250px); } }

.sf-menu li.sf-search {
  border: none; }
  .sf-menu li.sf-search input {
    display: inline;
    padding: 0 13px;
    height: 30px;
    line-height: 30px;
    background: #fff;
    margin: 13px 10px 0 0;
    font-size: 13px;
    color: #9c9b9b;
    border: 1px solid #d6d4d4; }

#block_top_menu .category-thumbnail {
  clear: both;
  width: 100%;
  float: none; }
  #block_top_menu .category-thumbnail div {
    float: left;
    width: 33.33333%; }
    #block_top_menu .category-thumbnail div img {
      max-width: 100%; }

#block_top_menu li.category-thumbnail {
  padding-right: 0; }

@media (max-width: 767px) {
  .opened-menu-overlay {
    display: none;
    z-index: 12;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh; }
    .is-menu-opened .opened-menu-overlay {
      display: block; } }
